.container {
  font-size: 72px;
  background-color: #000000;
  height: 1974px;
  display: flex;
}

.gradient-text {
  background: linear-gradient(90deg, #851910 0%, #fb261e 52.53%, #e87465 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

/* .container2 {
  height: 500px;
  background-color: #ffffff;
} */
